import React from 'react'
import type { ProfileResult } from '../data/ProfileFragment'
import s from './CardSection.module.sass'
import { MediumCard } from './MediumCard'

export function CardSection(props: {
	profiles: ProfileResult[]
	title: React.ReactNode
	action?: {
		onClick: () => void
		label: React.ReactNode
	}
}) {
	return (
		<div className={s.SectionWrapper}>
			<div className={s.Header}>
				<div className={s.Title}>{props.title}</div>
				{props.action && (
					<button type="button" className={s.Button} onClick={props.action.onClick}>
						{props.action.label}
					</button>
				)}
			</div>

			<div className={s.Section}>
				{props.profiles.map((profile) => (
					<MediumCard profile={profile} key={profile.id} />
				))}
			</div>
		</div>
	)
}
