import SvgFb from '../../svg/icons/Fb'
import SvgIg from '../../svg/icons/Ig'
import SvgTiktok from '../../svg/icons/Tiktok'
import SvgTwitter from '../../svg/icons/Twitter'
import SvgYt from '../../svg/icons/Yt'

export const links = {
	fb: { link: 'https://facebook.com/', icon: <SvgFb /> },
	ig: { link: 'https://instagram.com/', icon: <SvgIg /> },
	tiktok: { link: 'https://tiktok.com/', icon: <SvgTiktok /> },
	twitter: { link: 'https://twitter.com/', icon: <SvgTwitter /> },
	youtube: { link: 'https://youtube.com/', icon: <SvgYt /> },
}
