import * as React from 'react'

function SvgTwitter() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256">
			<path
				d="M81.238 230.43c95.126 0 147.145-78.808 147.145-147.145 0-2.24-.044-4.467-.148-6.685a105.228 105.228 0 0025.801-26.779c-9.268 4.117-19.239 6.89-29.7 8.142 10.677-6.402 18.875-16.533 22.74-28.607a103.65 103.65 0 01-32.837 12.553c-9.438-10.052-22.877-16.34-37.749-16.34-28.562 0-51.725 23.163-51.725 51.714 0 4.058.454 8.005 1.342 11.791-42.981-2.162-81.095-22.742-106.599-54.033a51.545 51.545 0 00-7.005 25.995c0 17.942 9.131 33.78 23.015 43.048a51.309 51.309 0 01-23.424-6.47c-.01.216-.01.431-.01.658 0 25.05 17.83 45.959 41.49 50.7a51.633 51.633 0 01-23.356.885c6.585 20.547 25.675 35.498 48.314 35.92-17.704 13.873-40 22.138-64.23 22.138-4.174 0-8.29-.238-12.338-.717 22.888 14.67 50.064 23.233 79.274 23.233"
				fill="currentColor"
				strokeWidth={0}
				strokeMiterlimit={10}
				style={{
					stroke: 'none',
					strokeWidth: 1,
					strokeDasharray: 'none',
					strokeLinecap: 'butt',
					strokeLinejoin: 'miter',
					strokeMiterlimit: 10,
					fill: '#000',
					fillRule: 'nonzero',
					opacity: 1,
				}}
			/>
		</svg>
	)
}

export default SvgTwitter
