import clsx from 'clsx'
import { useRouter } from 'next/dist/client/router'
import { ProfileStatus } from '../../generated/content'
import { createFetcher } from '../../packages/@necktip/next/api/jsonApiHandler'
import type { API } from '../../pages/api/v1/[handler]'
import type { ProfileResult } from '../data/ProfileFragment'
import SvgG from '../svg/icons/G'
import s from './FriendsTable.module.sass'

const api = createFetcher<API>('/api/v1/[handler]')

export function TableButton(props: {
	title: string
	profile: ProfileResult
	closeSearch: () => void
}) {
	const router = useRouter()

	const handleClick = async () => {
		const data = props.profile
		try {
			await api('updateProfileStatusWaiting', {
				id: data.id,
			})
			props.closeSearch()
			router.push('/')
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<button
			type="button"
			className={clsx(props.profile.status === ProfileStatus.waiting && s.WaitingButton, s.Button)}
			onClick={handleClick}>
			<div className={s.DesktopHidden}>
				<SvgG />
			</div>
			<span className={s.MobileHidden}>{props.title}</span>
		</button>
	)
}
