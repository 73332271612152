import type { VipStatus } from '../../../generated/content'
import SvgG from '../../svg/icons/G'

const statusColors = {
	gold: '#EBB428',
	silver: '#ABA9A9',
	bronze: '#A96A47',
}

const statusDiscount = {
	gold: '50%',
	silver: '20%',
	bronze: '10%',
}

export const setDiscount = (value: VipStatus) => {
	if (value) {
		return statusDiscount[value]
	}
}

export const setStatus = (value: VipStatus) => {
	return (
		<span style={{ fill: statusColors[value], height: 27, display: 'flex', alignItems: 'center' }}>
			<SvgG />
		</span>
	)
}
