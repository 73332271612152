import { useRouter } from 'next/dist/client/router'
import Link from 'next/link'
import React from 'react'
import SvgG from '../svg/icons/G'
import SvgGoodlokText from '../svg/icons/GoodlokText'
import s from './Header.module.sass'

export function Logo() {
	const router = useRouter()
	const locale = router.locale

	return (
		<Link href="/" locale={locale} passHref>
			<a aria-label="Zpět na homepage" className={s.LogoTitle}>
				<span className={s.onlySmall}>
					<SvgG />
				</span>
				<span className={s.onlyLarge}>
					<SvgGoodlokText />
				</span>
			</a>
		</Link>
	)
}
