import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { useRouter } from 'next/dist/client/router'
import Link from 'next/link'
import React from 'react'
import { ProfileStatus } from '../../generated/content'
import { createFetcher } from '../../packages/@necktip/next/api/jsonApiHandler'
import type { API } from '../../pages/api/v1/[handler]'
import { getFamiliarityName } from '../assets/helpers/familiarityHelper'
import { links } from '../assets/helpers/socialHelper'
import { setDiscount, setStatus } from '../assets/helpers/statusHelper'
import type { ProfileResult } from '../data/ProfileFragment'
import SvgCross from '../svg/icons/Cross'
import { Carousel } from './Carousel'
import s from './ProfileCard.module.sass'
import { SocialRow } from './SocialRow'

const api = createFetcher<API>('/api/v1/[handler]')

export function ProfileCard(props: { profile: ProfileResult }) {
	const router = useRouter()

	const removeUserVisited = async () => {
		const data = props.profile
		try {
			await api('updateProfileVisited', {
				id: data.id,
				visited: data.visited || 0,
			})
			router.push('/')
		} catch (e) {
			console.log(e)
		}
	}

	const handleClick = async () => {
		const data = props.profile
		try {
			await api('updateProfileStatusWaiting', {
				id: data.id,
			})
			router.push('/')
		} catch (e) {
			console.log(e)
		}
	}

	const isWaiting = props.profile.status === ProfileStatus.waiting

	return (
		<div className={s.ProfileCard}>
			<Link href="/" passHref>
				<a className={s.CloseBtn}>
					<SvgCross />
				</a>
			</Link>
			<div className={s.ImgGroupWrapper}>
				{props.profile.gallery ? (
					<Carousel gallery={props.profile.gallery} />
				) : (
					<span className={s.Placeholder} />
				)}
			</div>
			<div className={s.TopContent}>
				<span>
					<span>
						{(props.profile.vipStatus && (
							<div className={s.Tooltip}>
								<span>{setStatus(props.profile.vipStatus)}</span>
								<span className={clsx(s.TooltipText, s.TooltipTextTop)}>
									{setDiscount(props.profile.vipStatus)}
								</span>
							</div>
						)) || <div className={s.SmallText}>Beze slevy</div>}
					</span>
					{/* TODO: credits dynamicly & add if credits */}
					<span className={s.Credits}>450 kreditů</span>
				</span>
				<span>{props.profile.familiarity && getFamiliarityName(props.profile.familiarity)}</span>
			</div>
			<div className={s.BottomContent}>
				<div className={s.ContentWrapper}>
					{props.profile.vokativ && <span className={s.Vokativ}>{props.profile.vokativ}!</span>}
					{props.profile.fullName && <span> &nbsp;{props.profile.fullName}</span>}
					<div className={s.TagGroup}>
						{props.profile.tags.map((tag) => (
							<span className={s.Tag} key={tag.name}>
								{tag.name}
							</span>
						))}
					</div>
					{/* {props.profile.aliases && <div className={s.Aliases}>({props.profile.aliases})</div>} */}
					<div className={s.SmallText}>
						Byl tu:&nbsp;&nbsp;
						<SvgCross />
						&nbsp;{props.profile.visited ? props.profile.visited : '0'}
					</div>
				</div>
				{props.profile.quickNote && (
					<div className={s.Note}>
						<span className={s.Info}>
							<span>i</span>
						</span>
						<RichTextRenderer source={props.profile.quickNote} />
					</div>
				)}
			</div>
			{(props.profile.facebook ||
				props.profile.instagram ||
				props.profile.youtube ||
				props.profile.tiktok ||
				props.profile.twitter) && (
				<div className={s.Social}>
					{props.profile.facebook && (
						<SocialRow
							social={{
								link: links.fb.link,
								icon: links.fb.icon,
							}}
							acc={props.profile.facebook}
						/>
					)}
					{props.profile.instagram && (
						<SocialRow
							social={{
								link: links.ig.link,
								icon: links.ig.icon,
							}}
							acc={props.profile.instagram}
						/>
					)}
					{props.profile.tiktok && (
						<SocialRow
							social={{
								link: links.tiktok.link,
								icon: links.tiktok.icon,
							}}
							acc={props.profile.tiktok}
						/>
					)}
					{props.profile.twitter && (
						<SocialRow
							social={{
								link: links.twitter.link,
								icon: links.twitter.icon,
							}}
							acc={props.profile.twitter}
						/>
					)}
					{props.profile.youtube && (
						<SocialRow
							social={{
								link: links.youtube.link,
								icon: links.youtube.icon,
							}}
							acc={props.profile.youtube}
						/>
					)}
				</div>
			)}

			<div className={s.BtnPay}>
				<button type="button">Zaplatit z kreditů</button>
			</div>
			{isWaiting ? (
				<div>
					<div className={s.BtnConfirm}>
						<button type="button" onClick={removeUserVisited}>
							Dorazil!
						</button>
					</div>
				</div>
			) : (
				<div className={s.BtnPin}>
					<button type="button" onClick={handleClick}>
						Brzy přijde!
					</button>
				</div>
			)}
		</div>
	)
}
