import React, { ChangeEventHandler, MouseEventHandler } from 'react'
import SvgCross from '../svg/icons/Cross'
import s from './SearchField.module.sass'

export function SearchField(props: {
	searchValue: string
	handleClose: MouseEventHandler<HTMLButtonElement>
	handleChange: ChangeEventHandler<HTMLInputElement>
}) {
	return (
		<div className={s.SearchBar}>
			<div className={s.InputWrapper}>
				<input
					type="text"
					value={props.searchValue}
					className={s.Input}
					// eslint-disable-next-line jsx-a11y/no-autofocus
					autoFocus
					placeholder="Piš jméno, ig, aliasy, cokoliv."
					onChange={props.handleChange}
				/>
				<button type="button" className={s.CloseBtn} onClick={props.handleClose}>
					<SvgCross />
				</button>
			</div>
		</div>
	)
}
