import * as React from 'react'

function SvgChevronUp() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="1em"
			height="1em"
			fill="currentColor"
			className="chevronUp_svg__bi chevronUp_svg__bi-chevron-up"
			viewBox="0 0 16 16">
			<path
				fillRule="evenodd"
				d="M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z"
			/>
		</svg>
	)
}

export default SvgChevronUp
