import { NextRouter, useRouter } from 'next/dist/client/router'
import { useCallback } from 'react'

type TransitionOptions = Parameters<NextRouter['replace']>[2]

export function useRefresh() {
	const router = useRouter()

	const refresh = useCallback(
		(options?: TransitionOptions | undefined) => {
			return router.replace(router.asPath, router.asPath, options)
		},
		[router]
	)

	return refresh
}
