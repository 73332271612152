import { RichTextRenderer } from '@contember/react-client'
import NextImage from 'next/image'
import Link from 'next/link'
import React, { useState } from 'react'
import { createFetcher } from '../../packages/@necktip/next/api/jsonApiHandler'
import type { API } from '../../pages/api/v1/[handler]'
import { getFamiliarityName } from '../assets/helpers/familiarityHelper'
import { setStatus } from '../assets/helpers/statusHelper'
import { useRefresh } from '../assets/helpers/useRefresh'
import type { ProfileResult } from '../data/ProfileFragment'
import SvgCheckmark from '../svg/icons/Checkmark'
import SvgChevronUp from '../svg/icons/ChevronUp'
import { CardButton } from './CardButton'
import s from './MediumCard.module.sass'

const api = createFetcher<API>('/api/v1/[handler]')

export function MediumCard(props: { profile: ProfileResult }) {
	const [openText, setOpenText] = useState(false)

	const expandText = () => {
		setOpenText(true)
	}

	const shortenText = () => {
		setOpenText(false)
	}

	const refresh = useRefresh()

	const removeUserVisited = async () => {
		const data = props.profile
		try {
			await api('updateProfileVisited', {
				id: data.id,
				visited: data.visited || 0,
			})
			refresh()
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<div>
			<div className={s.Card}>
				<CardButton
					title={
						<span className={s.BtnIcon}>
							<SvgCheckmark />
						</span>
					}
					onClick={removeUserVisited}
				/>
				<div className={s.Body}>
					<Link href={{ pathname: '/', query: { friend: props.profile.id } }} passHref>
						<a className={s.ImgWrapper}>
							{props.profile.gallery?.items ? (
								props.profile.gallery.items[0]?.image?.url && (
									<NextImage
										className={s.Img}
										key={props.profile.gallery.items[0].image.url}
										src={props.profile.gallery.items[0].image.url}
										layout="fill"
										objectFit="cover"
										objectPosition="50% 20%"
									/>
								)
							) : (
								<span className={s.Placeholder} />
							)}
						</a>
					</Link>
					<Link href={{ pathname: '/', query: { friend: props.profile.id } }} passHref>
						<a className={s.TopContent}>
							{(props.profile.vipStatus && setStatus(props.profile.vipStatus)) ||
								(props.profile.importance && props.profile.importance)}
							<span>
								{props.profile.familiarity && getFamiliarityName(props.profile.familiarity)}
							</span>
						</a>
					</Link>
					<div className={s.BottomContent}>
						<Link href={{ pathname: '/', query: { friend: props.profile.id } }} passHref>
							<a className={s.ContentWrapper}>
								<span className={s.Vokativ}>{props.profile.vokativ}!</span>
								<span> &nbsp;{props.profile.fullName}</span>
							</a>
						</Link>
						{props.profile.quickNote && (
							<div className={s.Note}>
								<div className={s.NoteText}>
									<div className={s.NotePreview} onClick={expandText} aria-hidden="true">
										<div className={s.NoteBody}>
											<span className={s.Info}>
												<span>i</span>
											</span>
											<div className={s.LimitedText}>
												<RichTextRenderer source={props.profile.quickNote} />
											</div>
										</div>
										<div className={s.ArrowUp}>
											<SvgChevronUp />
										</div>
									</div>
									{openText && (
										<div className={s.NoteTextExpanded}>
											<div className={s.NotePreview} onClick={shortenText} aria-hidden="true">
												<span className={s.Info}>
													<span>i</span>
												</span>
												<div className={s.ExpandedText}>
													<RichTextRenderer source={props.profile.quickNote} />
												</div>
												<div className={s.ArrowDown}>
													<SvgChevronUp />
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
