import clsx from 'clsx'
import Link from 'next/link'
import React, { ReactNode } from 'react'
import type { ProfileResult } from '../data/ProfileFragment'
import { ModalUser } from './ModalUser'
import s from './TableRow.module.sass'

export function TableRowBody(props: {
	first: ReactNode
	second: ReactNode
	third: ReactNode
	fourth: ReactNode
	fifth: ReactNode
	profile: ProfileResult
	activeFriend: string
}) {
	return (
		<div>
			<div className={clsx(s.Row, s.RowBody)}>
				<div className={clsx(s.RowItem, s.Pointer)}>
					<Link href={{ pathname: '/', query: { friend: props.profile.id } }} passHref>
						{props.first}
					</Link>
				</div>
				<div className={clsx(s.RowItem, s.Pointer)}>
					<Link href={{ pathname: '/', query: { friend: props.profile.id } }} passHref>
						{props.second}
					</Link>
					<div className={clsx(s.ArrItemGroup, s.DesktopHidden)}>
						<span className={s.ArrItem}>{props.fourth}</span>
						<span className={s.ArrItem}>{props.third}</span>
					</div>
				</div>
				<div className={clsx(s.RowItem, s.ItemList, s.LowerImportance, s.MobileHidden)}>
					{props.third}
				</div>

				<div className={clsx(s.RowItem, s.ItemNumber, s.MobileHidden)}>{props.fourth}</div>
				<div className={s.RowItem}>{props.fifth}</div>
			</div>
			{props.activeFriend === props.profile.id && <ModalUser profile={props.profile} />}
		</div>
	)
}
