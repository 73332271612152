import clsx from 'clsx'
import React, { ReactNode } from 'react'
import s from './TableRow.module.sass'

export function TableRowHeader(props: {
	first: ReactNode
	second: ReactNode
	third: ReactNode
	fourth: ReactNode
	fifth: ReactNode
}) {
	return (
		<div className={s.Row}>
			<div className={s.RowItem}>{props.first}</div>
			<div className={s.RowItem}>{props.second}</div>
			<div className={clsx(s.RowItem, s.ItemList, s.MobileHidden)}>{props.third}</div>

			<div className={clsx(s.RowItem, s.ItemNumber, s.MobileHidden)}>{props.fourth}</div>
			<div className={s.RowItem}>{props.fifth}</div>
		</div>
	)
}
