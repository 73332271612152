import type { Familiarity } from '../../../generated/content'

const familiarityNames = {
	tykani: 'Tykání',
	slusneTykani: 'Slušné tykání',
	pratelskeVykani: 'Přátelské vykání',
	cizi: 'Cizí člověk',
	sikana: 'Šikana!',
}

export const getFamiliarityName = (value: Familiarity) => {
	return familiarityNames[value] ?? value
}
