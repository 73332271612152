import Link from 'next/link'
import React from 'react'
import s from './SocialRow.module.sass'

export function SocialRow(props: { social: { link: string; icon: JSX.Element }; acc: string }) {
	return (
		<div className={s.Row}>
			<Link href={`${props.social.link}${props.acc}`}>
				<a className={s.LinkWrapper}>
					<span className={s.IconWrapper}>{props.social.icon}</span>
					<span className={s.AccWrapper}>{props.acc}</span>
				</a>
			</Link>
		</div>
	)
}
