import Link from 'next/link'

export const maybeInstagramLink = (name: string) => {
	if (name.indexOf(' ') >= 0) {
		return name
	}
	return (
		<Link href={`https://instagram.com/${name}`}>
			<a>@{name}</a>
		</Link>
	)
}
