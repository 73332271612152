import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import { setCookie } from 'nookies'
import React, { ChangeEventHandler, useState } from 'react'
import { useQuery } from 'react-query'
import { useRefresh } from '../app/assets/helpers/useRefresh'
import { CardSection } from '../app/components/CardSection'
import { FriendsTable } from '../app/components/FriendsTable'
import { Header } from '../app/components/Header'
import { Logo } from '../app/components/Logo'
import { SearchField } from '../app/components/SearchField'
import { ProfileFragment, ProfileResult } from '../app/data/ProfileFragment'
import SvgCross from '../app/svg/icons/Cross'
import { isCookieAuth, PASSWORD_COOKIE_KEY } from '../app/utils/ensureCookieAuth'
import { OrderDirection, ProfileStatus, Thunder } from '../generated/content'
import { createFetcher } from '../packages/@necktip/next/api/jsonApiHandler'
import { getZeusConnection } from '../packages/@necktip/next/loaders/graphql'
import s from './../app/styles/Home.module.sass'
import type { API } from './api/v1/[handler]'

const api = createFetcher<API>('/api/v1/[handler]')

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export const filterBySearch = (profiles: ProfileResult[], search: string) => {
	const searchedText = search?.trim().toLowerCase()

	const sortProfilesBySearch = profiles.filter(
		(profile) =>
			profile.name?.toLowerCase().includes(searchedText) ||
			profile.fullName?.toLowerCase().includes(searchedText) ||
			profile.aliases?.toLowerCase().includes(searchedText) ||
			profile.vokativ?.toLowerCase().includes(searchedText)
	)
	return sortProfilesBySearch
}

export function DashboardPage(props: CommonPageProps & { auth: true }) {
	const refresh = useRefresh()

	const [searchVal, setSearchVal] = useState('')

	const [searchActive, setSearchActive] = useState(false)

	const hideCards = () => {
		setSearchActive(true)
	}

	const showCards = () => {
		setSearchActive(false)
	}

	const profilesList = props.data.listProfile
	const filteredProfiles = filterBySearch(profilesList, searchVal)

	const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		hideCards()
		setSearchVal(e.currentTarget.value)
	}

	const handleClose = () => {
		showCards()
		setSearchVal('')
	}

	const waitingProfiles = props.data.listProfile.filter(
		(profile) => profile.status === ProfileStatus.waiting
	)

	useQuery(
		[],
		async () => {
			return refresh({ scroll: false })
		},
		{
			refetchInterval: 20 * 1000, // 20 s
		}
	)

	return (
		<div>
			<Head>
				<title>Goodlok kámoši</title>
				<meta name="description" content="Goodlok kámoši" />
				<link rel="icon" href="/favicon.ico" />
			</Head>
			<Header />
			<SearchField searchValue={searchVal} handleClose={handleClose} handleChange={handleChange} />
			<div className={s.Container}>
				<div className={s.Table}>
					{!searchActive && waitingProfiles.length > 0 && (
						<CardSection
							profiles={waitingProfiles}
							title="Brzy dorazí:"
							action={{
								onClick: async () => {
									const ids = waitingProfiles.map((profile) => profile.id)
									await api('bulkUpdateProfileStatusRegular', {
										ids,
									})
									refresh()
								},
								label: (
									<>
										<SvgCross />
										Smazat vše
									</>
								),
							}}
						/>
					)}
					{!searchActive && props.data.nearbyProfiles.listProfile.length > 0 && (
						<CardSection profiles={props.data.nearbyProfiles.listProfile} title="Poblíž (iOS):" />
					)}
					<FriendsTable
						profiles={filteredProfiles}
						activeFriend={props.friend}
						closeSearch={handleClose}
					/>
				</div>
			</div>
		</div>
	)
}

export default function CommonPage(props: CommonPageProps) {
	if (props.auth) {
		return <DashboardPage {...props} />
	}

	return (
		<div>
			<Head>
				<title>Goodlok kámoši</title>
				<meta name="description" content="Goodlok kámoši" />
				<link rel="icon" href="/favicon.ico" />
			</Head>
			<div className={s.LogoWrapper}>
				<Logo />
			</div>
			<div className={s.Login}>
				<form
					method="POST"
					onSubmit={(e) => {
						e.preventDefault()
						const password = String(new FormData(e.currentTarget).get('password'))

						setCookie(null, PASSWORD_COOKIE_KEY, password, {
							maxAge: 30 * 24 * 60 * 60,
							path: '/',
						})

						window.location.reload()
					}}>
					<input type="password" name="password" placeholder="Heslo" />
					<button className={s.Button}>Přihlásit se</button>
				</form>
			</div>
		</div>
	)
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
	if (isCookieAuth(context.req)) {
		const content = getZeusConnection(Thunder, 'content')
		const friend = String(context.query?.friend ?? '')

		const nearbyTimeStart = new Date(Date.now() - 5 * 60 * 1000) // 5 minutes in ms

		const data = await content.query({
			listProfile: [
				{
					orderBy: [{ vipStatus: OrderDirection.asc }, { fullName: OrderDirection.asc }],
				},
				ProfileFragment(),
			],
			__alias: {
				nearbyProfiles: {
					listProfile: [
						{
							orderBy: [{ iosNearbyAt: OrderDirection.desc }],
							filter: { iosNearbyAt: { gt: nearbyTimeStart.toISOString() } },
						},
						ProfileFragment(),
					],
				},
			},
		})

		return {
			props: {
				friend,
				auth: true as const,
				data,
				serverTime: new Date().toString(),
			},
		}
	}

	return {
		props: {
			auth: false as const,
		},
	}
}
