import clsx from 'clsx'
import SvgChevronUp from '../svg/icons/ChevronUp'
import s from './Carousel.module.sass'

export function CarouselButton(props: {
	direction: 'prev' | 'next'
	enabled: boolean
	onClick: () => void
}) {
	return (
		<button
			type="button"
			className={clsx(
				s.embla__button,
				props.direction === 'prev' ? s.embla__button__prev : s.embla__button__next
			)}
			onClick={props.onClick}
			disabled={!props.enabled}>
			<SvgChevronUp />
		</button>
	)
}
