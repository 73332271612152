import * as React from 'react'

function SvgTiktok() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256">
			<path
				d="M238.615 65.08c-14.408 0-27.703-4.773-38.377-12.825-12.242-9.231-21.04-22.77-24.145-38.376a63.911 63.911 0 01-1.227-11.915h-41.158v112.463l-.05 61.601c0 16.469-10.725 30.434-25.591 35.343a37.418 37.418 0 01-13.828 1.835 37.095 37.095 0 01-17.04-5.226c-10.735-6.42-18.012-18.068-18.21-31.392-.312-20.824 16.524-37.8 37.331-37.8 4.109 0 8.052.67 11.747 1.888V98.891a80.984 80.984 0 00-11.87-.876c-22.776 0-44.076 9.466-59.304 26.523-11.509 12.89-18.413 29.333-19.477 46.578-1.395 22.652 6.893 44.185 22.97 60.074a80.098 80.098 0 007.435 6.495c13.783 10.601 30.633 16.351 48.379 16.351 4.008 0 7.974-.297 11.87-.874 16.577-2.456 31.873-10.044 43.944-21.978 14.833-14.66 23.028-34.122 23.115-54.837l-.213-91.99a103.899 103.899 0 0023.115 13.481c12.915 5.448 26.608 8.21 40.699 8.207V65.072c.01.008-.107.008-.115.008z"
				fill="currentColor"
				strokeWidth={0}
				strokeMiterlimit={10}
				style={{
					stroke: 'none',
					strokeWidth: 1,
					strokeDasharray: 'none',
					strokeLinecap: 'butt',
					strokeLinejoin: 'miter',
					strokeMiterlimit: 10,
					fill: '#000',
					fillRule: 'nonzero',
					opacity: 1,
				}}
			/>
		</svg>
	)
}

export default SvgTiktok
