import { useRouter } from 'next/dist/client/router'
import React, { useRef } from 'react'
import { useClickAway } from 'react-use'
import { ProfileStatus } from '../../generated/content'
import { createFetcher } from '../../packages/@necktip/next/api/jsonApiHandler'
import type { API } from '../../pages/api/v1/[handler]'
import type { ProfileResult } from '../data/ProfileFragment'
import SvgCross from '../svg/icons/Cross'
import s from './ModalUser.module.sass'
import { ProfileCard } from './ProfileCard'

const api = createFetcher<API>('/api/v1/[handler]')

export function ModalUser(props: { profile: ProfileResult }) {
	const ref = useRef(null)
	const router = useRouter()

	useClickAway(ref, () => {
		router.replace('/')
	})

	const removeUser = async () => {
		const data = props.profile
		try {
			await api('updateProfileStatusRegular', {
				id: data.id,
			})
			router.push('/')
		} catch (e) {
			console.log(e)
		}
	}

	const isWaiting = props.profile.status === ProfileStatus.waiting

	return (
		<div className={s.Modal} id="modal">
			<div className={s.ModalContent} ref={ref}>
				<ProfileCard profile={props.profile} />
				{isWaiting && (
					<div className={s.BtnDecline}>
						<button type="button" onClick={removeUser}>
							<SvgCross />
							Nakonec se neukáže
						</button>
					</div>
				)}
			</div>
		</div>
	)
}
