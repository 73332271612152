import * as React from 'react'

function SvgYt() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256">
			<path
				d="M248.768 67.33c-2.9-10.846-11.442-19.388-22.286-22.287C206.826 39.775 128 39.775 128 39.775s-78.826 0-98.482 5.268C18.674 47.942 10.131 56.484 7.232 67.33 1.964 86.985 1.964 128 1.964 128s0 41.015 5.268 60.67c2.9 10.846 11.442 19.388 22.286 22.287 19.656 5.268 98.482 5.268 98.482 5.268s78.826 0 98.482-5.268c10.844-2.899 19.387-11.441 22.286-22.286 5.268-19.656 5.268-60.671 5.268-60.671s0-41.015-5.268-60.67zm-145.975 98.48V90.19L168.28 128l-65.488 37.81z"
				fill="currentColor"
				strokeWidth={0}
				strokeMiterlimit={10}
				style={{
					stroke: 'none',
					strokeWidth: 1,
					strokeDasharray: 'none',
					strokeLinecap: 'butt',
					strokeLinejoin: 'miter',
					strokeMiterlimit: 10,
					fill: '#000',
					fillRule: 'nonzero',
					opacity: 1,
				}}
			/>
		</svg>
	)
}

export default SvgYt
