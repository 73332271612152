import NextImage from 'next/image'
import React from 'react'
import { setDiscount, setStatus } from '../assets/helpers/statusHelper'
import { maybeInstagramLink } from '../assets/helpers/stringHelper'
import type { ProfileResult } from '../data/ProfileFragment'
import s from './FriendsTable.module.sass'
import { TableButton } from './TableButton'
import { TableRowBody } from './TableRowBody'
import { TableRowHeader } from './TableRowHeader'

export function FriendsTable(props: {
	profiles: ProfileResult[]
	activeFriend: string
	closeSearch: () => void
}) {
	return (
		<div className={s.Container}>
			<div className={s.Titles}>
				<TableRowHeader
					first="Čau"
					second="Celé jméno"
					third="Ig, jméno"
					fourth="Důležitost"
					fifth="Přijde brzy?"
				/>
			</div>
			{props.profiles.map((profile) => (
				<TableRowBody
					profile={profile}
					key={profile.id}
					activeFriend={props.activeFriend}
					first={
						<div className={s.Img}>
							{profile.gallery?.items ? (
								profile.gallery.items[0]?.image?.url && (
									<NextImage
										key={profile.gallery.items[0].image.url}
										src={profile.gallery.items[0].image.url}
										layout="fill"
										objectFit="cover"
										objectPosition="50% 20%"
									/>
								)
							) : (
								<span className={s.Placeholder} />
							)}
						</div>
					}
					second={
						<div>
							<div className={s.Name}>{profile.fullName}</div>
							{profile.tags.map((tag: { name: string }) => (
								<span className={s.ArrItem} key={tag.name}>
									{tag.name}
								</span>
							))}
						</div>
					}
					third={profile.name && maybeInstagramLink(profile.name)}
					fourth={
						(profile.vipStatus && (
							<div className={s.Tooltip}>
								<span>{setStatus(profile.vipStatus)}</span>
								<span className={s.TooltipText}>{setDiscount(profile.vipStatus)}</span>
							</div>
						)) || <div className={s.SmallText}>Beze slevy</div>
					}
					fifth={
						<TableButton title="Čekáme ho" profile={profile} closeSearch={props.closeSearch} />
					}
				/>
			))}
		</div>
	)
}
