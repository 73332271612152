import useEmblaCarousel from 'embla-carousel-react'
import NextImage from 'next/image'
import React, { useCallback, useEffect, useState } from 'react'
import { filterNonEmpty } from '../assets/helpers/filterNonEmpty'
import type { ProfileResult } from '../data/ProfileFragment'
import s from './Carousel.module.sass'
import { CarouselButton } from './CarouselButton'

export function Carousel(props: { gallery?: ProfileResult['gallery'] }) {
	const [viewportRef, embla] = useEmblaCarousel({
		loop: true,
		skipSnaps: false,
	})
	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])
	const onSelect = useCallback(() => {
		if (!embla) return
		setPrevBtnEnabled(embla.canScrollPrev())
		setNextBtnEnabled(embla.canScrollNext())
	}, [embla])

	useEffect(() => {
		if (!embla) return
		embla.on('select', onSelect)
		onSelect()

		return () => {
			embla.off('select', onSelect)
		}
	}, [embla, onSelect])

	return (
		<div className={s.embla}>
			<div className={s.embla__viewport} ref={viewportRef}>
				<div className={s.embla__container}>
					{props.gallery &&
						props.gallery.items
							?.map((item) => item.image)
							.filter(filterNonEmpty)
							.map((image) => (
								<div className={s.embla__slide} key={image.url}>
									<div className={s.embla__slide__inner}>
										<NextImage src={image.url} layout="fill" objectFit="contain" />
									</div>
								</div>
							))}
				</div>
			</div>
			{props.gallery && props.gallery.items.length > 1 && (
				<>
					<CarouselButton direction="prev" onClick={scrollPrev} enabled={prevBtnEnabled} />
					<CarouselButton direction="next" onClick={scrollNext} enabled={nextBtnEnabled} />
				</>
			)}
		</div>
	)
}
