import * as React from 'react'

function SvgCross() {
	return (
		<svg width="1em" height="1em" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg">
			<g fill="none" fillRule="evenodd" strokeLinecap="round">
				<path d="M1 .5l9.899 9.899M1 10.399L10.899.5" />
			</g>
		</svg>
	)
}

export default SvgCross
