import type { NextApiRequestCookies } from 'next/dist/server/api-utils'
export const PASSWORD_COOKIE_KEY = 'goodlok-password'

export class NotAuthorizedError extends Error {}

type Req = { cookies: NextApiRequestCookies }

export function ensureCookieAuth(req: Req) {
	if (!isCookieAuth(req)) {
		throw new NotAuthorizedError()
	}
}

export function isCookieAuth(req: Req) {
	return (
		String(req.cookies[PASSWORD_COOKIE_KEY]).toLowerCase() ===
		String(process.env.GOODLOK_PASSWORD).toLowerCase()
	)
}
