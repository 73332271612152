import * as React from 'react'

function SvgFb() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256">
			<path
				d="M147.58 254.036v-114.81h38.598l5.938-44.95H147.58V65.65c0-12.973 3.622-21.82 22.239-21.82h23.574V3.747a310.352 310.352 0 00-34.559-1.781c-34.203 0-57.63 20.782-57.63 59.231v33.075H62.608v44.95h38.598v114.813h46.375z"
				fill="currentColor"
				strokeWidth={0}
				strokeMiterlimit={10}
				style={{
					stroke: 'none',
					strokeWidth: 1,
					strokeDasharray: 'none',
					strokeLinecap: 'butt',
					strokeLinejoin: 'miter',
					strokeMiterlimit: 10,
					fill: '#000',
					fillRule: 'nonzero',
					opacity: 1,
				}}
			/>
		</svg>
	)
}

export default SvgFb
